<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
	</div>
</template>

<script>
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "ChooseTheme",

	data() {
		return {
			options: [
				{
					title: "app.choose_theme.theme_database.board.title",
					icon: "tree-branch",
					route: {
						name: "ChooseThemeDatabase",
					},
					fade: this.$store.getters["user/getLanguage"] != "en",
				},
				{
					title: "app.choose_theme.summary.board.title",
					icon: "exclamation-mark",
					route: {
						name: "ChooseThemeSummary",
					},
				},
			],
		};
	},

	components: {
		AppBoxTheme,
		StageIntroDialog,
	},

	methods: {
		proceed(route) {
			try {
				if (!route) {
					return;
				}
				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
